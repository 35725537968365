$black: #000;
$white: #fff;

$think7_bg_white: #f7fdf4;
$think7_bg_red: #b72e29;
$think7_bg_grey: #e8efe7d9;

$cigi-light-grey: #e9e9e9;
$cigi-medium-light-grey: #aaa;
$cigi-medium-grey: #929292;
$cigi-text-grey: #6d6d6d;
$cigi-primary-colour: #e6023b;
$cigi-dark-pink: #bf0332;
